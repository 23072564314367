import React, { useState, useEffect } from 'react';
import UserGroupService from './UserGroupService'; // Assuming you have a service file for API calls
import { useParams, useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
import { FaArrowLeft } from "react-icons/fa6";

const UserGroupForm = () => {
    const { id } = useParams(); // Get the 'id' parameter from the URL
    const navigate = useNavigate(); // Hook for navigation

    // State to hold form data and manage changes
    const [userGroup, setUserGroup] = useState({
        name: '',
        description: '',
        phoneNumber: '',
        parentId: 0,
        subsidy: '',
        subsidyType: 'DAILY',
        kitchenId: '',
        billingAddress: '',
        address: '',
        lunchTimeStart: '',
        lunchTimeEnd: '',
        snacksTimeStart: '',
        snacksTimeEnd: '',
        dinnerTimeStart: '',
        dinnerTimeEnd: '',
        bufferTimeToLock: '',
        companyDomains: '',
        showGroupMenuOnlyEnum: 'DEFAULT'
    });

    // State to hold all user groups fetched from the server
    const [allUserGroups, setAllUserGroups] = useState([]);

    // Fetch all user groups from the server when component mounts
    useEffect(() => {
        UserGroupService.getAll()
            .then(response => {
                setAllUserGroups(response.data.list); // Set the fetched user groups to state
            })
            .catch(error => {
                toast.error("Error fetching user groups")
                console.log('Error fetching user groups:', error);
            });
    }, []);

    // Fetch user group data for editing if 'id' exists
    useEffect(() => {
        if (id) {
            UserGroupService.get(Number(id))
                .then(response => {
                    const data = response.data;
                    setUserGroup({
                        ...data,
                        parentId: data.parent ? data.parent.id : null
                    });
                })
                .catch(error => {
                    toast.error("Error fetching user group details")
                    console.log('Error fetching user group details:', error);
                });
        }
    }, [id]);

    // Handle changes in form inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserGroup({ ...userGroup, [name]: value }); // Update the corresponding state field
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        if (id) {
            // If 'id' exists, update the user group
            UserGroupService.update(Number(id), userGroup)
                .then(response => {
                    navigate('/admin/UserGroup'); // Navigate to the user group list after successful update
                })
                .catch(error => {
                    toast.error("Error updating user group")
                    console.log('Error updating user group:', error);
                });
        } else {
            // If 'id' does not exist, create a new user group
            UserGroupService.create(userGroup)
                .then(response => {
                    navigate('/admin/UserGroup'); // Navigate to the user group list after successful creation
                })
                .catch(error => {
                    toast.error("Error creating user group")
                    console.log('Error creating user group:', error);
                });
        }
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons mb-2">
                <button onClick={() => navigate('/admin/userGroup')}
                    className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-200 rounded-lg">
                    <FaArrowLeft />
                    <span>Back</span>
                </button>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-xl">
                <div className='bg-white shadow-lg px-12 py-12 rounded-md'>
                    <h2 className="text-2xl font-semibold mb-6">{id ? 'Edit User Group' : 'Add User Group'}</h2>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={userGroup.name}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Description:</label>
                            <input
                                type="text"
                                name="description"
                                value={userGroup.description}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Phone Number:</label>
                            <input
                                type="text"
                                name="phoneNumber"
                                value={userGroup.phoneNumber}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Parent:</label>
                            <select
                                name="parentId"
                                value={userGroup.parentId}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            >
                                <option> Select Parent </option>
                                {allUserGroups.map(group => (
                                    <option key={group.id} value={group.id}>{group.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Kitchen ID:</label>
                            <input
                                type="text"
                                name="kitchenId"
                                value={userGroup.kitchenId}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            />
                        </div>
                        <div class="grid md:grid-cols-2 md:gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Subsidy:</label>
                                <input
                                    type="text"
                                    name="subsidy"
                                    value={userGroup.subsidy}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Subsidy Type:</label>
                                <select
                                    name="subsidyType"
                                    value={userGroup.subsidyType}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                >
                                    <option value="DAILY">DAILY</option>
                                    <option value="MONTHLY">MONTHLY</option>
                                    <option value="YEARLY">YEARLY</option>
                                </select>
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 md:gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Lunch Time Start:</label>
                                <input
                                    type="text"
                                    name="lunchTimeStart"
                                    value={userGroup.lunchTimeStart}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Lunch Time End:</label>
                                <input
                                    type="text"
                                    name="lunchTimeEnd"
                                    value={userGroup.lunchTimeEnd}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 md:gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Snacks Time Start:</label>
                                <input
                                    type="text"
                                    name="snacksTimeStart"
                                    value={userGroup.snacksTimeStart}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Snacks Time End:</label>
                                <input
                                    type="text"
                                    name="snacksTimeEnd"
                                    value={userGroup.snacksTimeEnd}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 md:gap-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Dinner Time Start:</label>
                                <input
                                    type="text"
                                    name="dinnerTimeStart"
                                    value={userGroup.dinnerTimeStart}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Dinner Time End:</label>
                                <input
                                    type="text"
                                    name="dinnerTimeEnd"
                                    value={userGroup.dinnerTimeEnd}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                />
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Buffer Time to Lock:</label>
                            <input
                                type="text"
                                name="bufferTimeToLock"
                                value={userGroup.bufferTimeToLock}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Company Domains:</label>
                            <input
                                type="text"
                                name="companyDomains"
                                value={userGroup.companyDomains}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Show Group Menu Only Enum:</label>
                            <select
                                name="showGroupMenuOnlyEnum"
                                value={userGroup.showGroupMenuOnlyEnum.name}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            >
                                <option value="DEFAULT">DEFAULT</option>
                                <option value="TRUE">TRUE</option>
                                <option value="FALSE">FALSE</option>
                            </select>
                        </div>
                        <div className='text-right'>
                            <button type="submit"
                                className="rounded-lg p-2 px-5 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500 transition-all">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UserGroupForm;