"use client";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Modal, Button } from "flowbite-react";
import { API_URL } from "../../../constants";
import { toast } from "react-toastify";
import Spinner from "../../../components/Spinner/Spinner";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { FaTrashAlt } from "react-icons/fa";
import { startOfMonth, endOfMonth, format } from "date-fns";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import {useUser} from "../../../context/UserContext";


export default function OrderStatus() {
    const {user} = useUser();
    const hidePricing = user?.selectedUserGroup?.hidePricing;
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    // Get the first and last day of the current month
    const firstDayOfMonth = format(startOfMonth(new Date()), "yyyy-MM-dd");
    const lastDayOfMonth = format(endOfMonth(new Date()), "yyyy-MM-dd");

    const [todaysOrders, setTodaysOrders] = useState([]);
    const [futureOrders, setFutureOrders] = useState([]);
    const [ordersHistory, setOrdersHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [fromDate, setFromDate] = useState(firstDayOfMonth); // Default to first day of month
    const [toDate, setToDate] = useState(lastDayOfMonth); // Default to last day of month

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const formattedFromDate = fromDate ? format(fromDate, "yyyy-MM-dd") : null;
            const formattedToDate = toDate ? format(toDate, "yyyy-MM-dd") : null;
            const response = await axios.post(
                `${API_URL}/api/customerOrderCart/history`,
                { fromDate: formattedFromDate, toDate: formattedToDate },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-Token": `${token}`,
                    },
                }
            );
            const { todays, history } = response.data;
            const todayDate = new Date().toISOString().split('T')[0];

            const todayOrders = [];
            const futureOrders = [];

            todays.forEach(order => {
                const itemDate = order.expectedDeliveryTime && order.expectedDeliveryTime.split(' ')[0];
                const isTodayOrder = itemDate === todayDate;
                if (isTodayOrder) {
                    todayOrders.push(order);
                } else {
                    futureOrders.push(order);
                }
            });

            setTodaysOrders(todayOrders);
            setFutureOrders(futureOrders);
            setOrdersHistory(history);
        } catch (error) {
            toast.error("Failed to fetch orders.");
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [token, fromDate, toDate]); // Update orders whenever date range or token changes

    const openModal = (item, orderId) => {
        setModalContent({ item, orderId });
        setModalIsOpen(true);
    };

    const deleteOrderedItem = async () => {
        try {
            await axios.delete(
                `${API_URL}/api/customerOrderCart/removeOrder/${modalContent.item.id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-Token": `${token}`,
                    },
                }
            );
            toast.success('Order item deleted successfully');
            fetchOrders(); // Re-fetch orders to update the list
        } catch (error) {
            const errorMessage = error.response?.data?.text || "Failed to delete order.";
            toast.error(errorMessage);
            console.error('Error deleting order:', error);
        } finally {
            setModalIsOpen(false);
        }
    };

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const categorizeOrders = (orders) => {
        return orders.reduce((acc, order) => {
            const slotName = order.slot ? order.slot.name : "UNKNOWN";
            if (!acc[slotName]) {
                acc[slotName] = [];
            }
            acc[slotName].push(order);
            return acc;
        }, {});
    };

    const goToOrderByDate = (deliveryDate) => {
        const formattedDeliveryDate = deliveryDate ? format(deliveryDate, "yyyy-MM-dd") : null;
        console.log("Navigating with deliveryDate:", deliveryDate, "Formatted:", formattedDeliveryDate);
        navigate(`/orderByDate/?deliveryDate=${formattedDeliveryDate}`);
    };

    const todaysOrdersBySlot = categorizeOrders(todaysOrders);
    const futureOrdersBySlot = categorizeOrders(futureOrders);

    const renderTableRows = (ordersBySlot) => (
        Object.keys(ordersBySlot).map((slotName) => (
            <React.Fragment key={slotName}>
                <Table.Row className="bg-gray-50">
                    <Table.Cell colSpan={7} className="text-gray-950 py-3 text-sm uppercase font-extrabold">
                        {slotName}
                    </Table.Cell>
                </Table.Row>
                {ordersBySlot[slotName].map((order) =>
                    order.orders.map((item) => (
                        <Table.Row
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            key={item.itemId}
                        >
                            <Table.Cell className="w-1/6">{item.item}</Table.Cell>
                            <Table.Cell className="w-1/6">{item.quantity}</Table.Cell>
                            {!hidePricing ? <Table.Cell className="w-1/6">Rs. {parseInt(item.price)}</Table.Cell> : <Table.Cell className="w-1/6">-</Table.Cell>}
                            {!hidePricing ? <Table.Cell className="w-1/6">
                                Rs. {parseInt(item.price) * item.quantity}
                            </Table.Cell> : <Table.Cell className="w-1/6">-</Table.Cell>}
                            <Table.Cell className="w-1/6">
                                <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2 py-0.5 capitalize rounded">
                                    {order.status.name}
                                </span>
                            </Table.Cell>
                            <Table.Cell className="w-1/6">{order.expectedDeliveryTime}</Table.Cell>
                            <Table.Cell className="w-1/6">
                                <span className="flex items-center justify-end gap-4">
                                    <FaTrashAlt
                                        className="text-md text-gray-500 hover:text-lime-600 cursor-pointer transition-colors"
                                        onClick={() => openModal(item, order.id)}
                                    />
                                </span>
                            </Table.Cell>
                        </Table.Row>
                    ))
                )}
            </React.Fragment>
        ))
    );

    return (
        <div className="max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn">
            {loading ? (
                <Spinner />
            ) : (
                <div className="overflow-x-auto p-0.5">
                    <h1 className="font-bold text-2xl text-center mt-5 mb-10">
                        Order Status
                    </h1>

                    {/* Date Range Filter */}
                    <div className="flex gap-4 mb-8">
                        <DatePicker
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Select From Date"
                            className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
                        />

                        <DatePicker
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Select To Date"
                            className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
                        />
                        <Button onClick={fetchOrders} className="bg-lime-600 hover:bg-lime-700 transition-all">Search</Button>
                    </div>

                    {/* Today's Orders */}
                    <h4 className="font-bold text-lg mb-5">Today's Orders</h4>
                    <Table className="shadow-none drop-shadow-none table-fixed w-full">
                        <Table.Head>
                            <Table.HeadCell className="w-1/6 bg-zinc-100">Item</Table.HeadCell>
                            <Table.HeadCell className="w-1/6 bg-zinc-100">Quantity</Table.HeadCell>
                            <Table.HeadCell className="w-1/6 bg-zinc-100">Rate</Table.HeadCell>
                            <Table.HeadCell className="w-1/6 bg-zinc-100">Total</Table.HeadCell>
                            <Table.HeadCell className="w-1/6 bg-zinc-100">Status</Table.HeadCell>
                            <Table.HeadCell className="w-1/6 bg-zinc-100">Expected Delivery Time</Table.HeadCell>
                            <Table.HeadCell className="w-1/6 bg-zinc-100 text-right">Actions</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {renderTableRows(todaysOrdersBySlot)}
                        </Table.Body>
                    </Table>

                    {/* Future Orders */}
                    {futureOrders.length > 0 && (
                        <div>
                            <h4 className="font-bold text-lg mb-5 mt-10">Future Orders</h4>
                            <Table className="shadow-none drop-shadow-none table-fixed w-full">
                                <Table.Head>
                                    <Table.HeadCell className="w-1/6 bg-zinc-100">Item</Table.HeadCell>
                                    <Table.HeadCell className="w-1/6 bg-zinc-100">Quantity</Table.HeadCell>
                                    <Table.HeadCell className="w-1/6 bg-zinc-100">Rate</Table.HeadCell>
                                    <Table.HeadCell className="w-1/6 bg-zinc-100">Total</Table.HeadCell>
                                    <Table.HeadCell className="w-1/6 bg-zinc-100">Status</Table.HeadCell>
                                    <Table.HeadCell className="w-1/6 bg-zinc-100">Expected Delivery Time</Table.HeadCell>
                                    <Table.HeadCell className="w-1/6 bg-zinc-100 text-right">Actions</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {renderTableRows(futureOrdersBySlot)}
                                </Table.Body>
                            </Table>
                        </div>
                    )}

                    <div>
                        <h4 className="font-bold text-lg mb-5 mt-10">History {format(fromDate, "MMMM d, yyyy")} - {format(toDate, "MMMM d, yyyy")}</h4>
                        <Table className="drop-shadow-none border-collapse table-fixed w-full">
                            <Table.Head>
                                <Table.HeadCell className="w-1/6 bg-zinc-100">Date</Table.HeadCell>
                                <Table.HeadCell className="w-1/6 bg-zinc-100">Total</Table.HeadCell>
                                <Table.HeadCell className="w-1/6 bg-zinc-100">Office subsidy</Table.HeadCell>
                                <Table.HeadCell className="w-1/6 bg-zinc-100">Personal Liability</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {ordersHistory.map((order) => (
                                    <Table.Row
                                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                            key={order.date}
                                        >
                                        <Table.Cell>
                                            <span
                                                className="text-lime-600 cursor-pointer hover:text-lime-700 hover:underline transition-all"
                                                onClick={() => {
                                                    goToOrderByDate(order.date);
                                                }}
                                            >
                                            {formatDate(order.date)}
                                            </span>
                                        </Table.Cell>
                                        {!hidePricing ? <Table.Cell>Rs. {order.total}</Table.Cell> : <Table.Cell>-</Table.Cell>}
                                        {!hidePricing ? <Table.Cell>
                                            {parseInt(order.subsidy)
                                                ? "Rs. " + parseInt(order.subsidy)
                                                : "-"}
                                        </Table.Cell> : <Table.Cell>-</Table.Cell>}
                                        {!hidePricing ? <Table.Cell>
                                            {parseInt(order.personalLiability)
                                                ? "Rs. " + parseInt(order.personalLiability)
                                                : "-"}
                                        </Table.Cell> : <Table.Cell>-</Table.Cell>}
                                    </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    </div>
                    <Modal show={modalIsOpen} size="lg" onClose={() => setModalIsOpen(false)} popup>
                        <Modal.Header />
                        <Modal.Body>
                            <div className="text-center">
                                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-600" />
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Are you sure you want to delete this?
                                </h3>
                                <div className="flex justify-center gap-4">
                                    <Button color="failure" onClick={deleteOrderedItem}>
                                        {"Yes, I'm sure"}
                                    </Button>
                                    <button
                                        className="px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-white text-gray-900 focus:ring-4 enabled:hover:bg-gray-100 rounded-lg"
                                        onClick={() => setModalIsOpen(false)}>
                                        No, cancel
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
}