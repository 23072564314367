const ProcessStatus = {
    DELIVERED: 'DELIVERED',
    NEW: 'NEW',
    PREPARING: 'PREPARING',
    READY: 'READY',
    DELIVERING: 'DELIVERING',
    PACKING: 'PACKING',
    CANCELLED: 'CANCELLED',
    getAll() {
        return Object.values(this).filter(value => typeof value === 'string');
    }
};

export default ProcessStatus;
