import React, { useEffect, useState, useCallback } from "react";
import { Table, Button } from "flowbite-react";
import axios from 'axios';
import { API_URL } from "../../constants";
import { toast } from 'react-toastify';
import Spinner from "../../components/Spinner/Spinner";
import DateUtils from "../../utils/DateUtils";
import KitchenService from "../Admin/Kitchen/KitchenService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const KitchenOrderLists = () => {
  const token = localStorage.getItem('token');
  const [orderedItems, setOrderedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState("LUNCH");
  const [searchTerm, setSearchTerm] = useState("");
  const [userGroups, setUserGroups] = useState([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState({
    id: 0,
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const payload = { date: DateUtils.formatDate(currentDate), slot: selectedSlot, userGroupId: selectedUserGroup.id };
    try {
      const response = await axios.post(`${API_URL}/api/customerOrderCart/orders`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': `${token}`
        }
      });
      setOrderedItems(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [currentDate, selectedSlot, token, selectedUserGroup]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    KitchenService.listMyGroups()
        .then((response) => {
          setUserGroups(response.data);
        })
        .catch((error) => {
          toast.error("Error fetching my groups");
          console.error("Error fetching my groups:", error);
        });
  }, [token])

  const handleStatusChange = async (cartIds, status) => {
    const payload = { cartIds: cartIds };
    const URL = status === 'PREPARING' ? 'api/customerOrderCart/markAsPreparing' : 'api/customerOrderCart/markAsReady';
    try {
      await axios.put(`${API_URL}/${URL}`, payload, {
        headers: {
          'X-Auth-Token': `${token}`
        }
      });
      toast.success(`Order status changed to ${status}`);
      fetchData();
    } catch (error) {
      toast.error(`Error changing status ${status}`);
    }
  };

  const handlePreviousDay = () => {
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    setCurrentDate(previousDate);
  };

  const handleNextDay = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    setCurrentDate(nextDate);
  };

  const handleSlotDropdownChange = (e) => {
    setSelectedSlot(e.target.value);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleGroupDropdownChange = async (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    setSelectedUserGroup({ id: selectedValue });
  };

  const groupByCategory = (items) => {
    return items.reduce((acc, item) => {
      const category = item.menuItem.selectedCategory.name;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
  };

  const groupedItems = groupByCategory(orderedItems);

  const renderTableBody = () => {
    return Object.keys(groupedItems).map((category) => {
      const items = groupedItems[category];
      const totalQuantity = items.reduce((sum, item) => sum + item.quantity, 0);

      return (
          <React.Fragment key={category}>
            <Table.Row className="bg-gray-200">
              <Table.Cell colSpan={5} className="font-semibold">{category} - Total Quantity: {totalQuantity}</Table.Cell>
            </Table.Row>
            {items.map((item, index) => (
                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell>{item.menuItem.name}</Table.Cell>
                  <Table.Cell>{item.quantity}</Table.Cell>
                  <Table.Cell>{item.status.name}</Table.Cell>
                  <Table.Cell>{item.expectedDeliveryTime}</Table.Cell>
                  <Table.Cell>
                    {item.status.name === "NEW" && (
                        <button
                            className="bg-lime-600 font-semibold rounded-lg px-6 py-3 text-white text-sm hover:bg-lime-500 focus:ring-2 focus:ring-lime-500 transition-all"
                            onClick={() => handleStatusChange(item.cartIds, "PREPARING")}
                        >
                          Mark As Preparing
                        </button>
                    )}
                    {item.status.name === "PREPARING" && (
                        <button
                            className="bg-amber-500 font-semibold rounded-lg px-6 py-3 text-white text-sm hover:bg-amber-400 focus:ring-2 focus:ring-amber-400 transition-all"
                            onClick={() => handleStatusChange(item.cartIds, "READY")}
                        >
                          Mark As Ready
                        </button>
                    )}
                    {item.status.name === "READY" && (
                        <button
                            className="bg-slate-700 font-semibold rounded-lg px-6 py-3 text-white text-sm hover:bg-slate-700"
                            disabled
                        >
                          Ready
                        </button>
                    )}
                  </Table.Cell>
                </Table.Row>
            ))}
          </React.Fragment>
      );
    });
  };

  return (
      <div className="max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn">
        <div className="calendar-header mb-6">
          <div className="nav-buttons items-center">
            <button onClick={handlePreviousDay} className="bg-gray-100 font-semibold rounded-full w-10 h-10 flex justify-center text-gray-900 text-sm hover:bg-lime-600 hover:text-white focus:ring-2 focus:ring-lime-500 transition-all">❮</button>
            <h2 className="current-date">{DateUtils.formatDate(currentDate)}</h2>
            <button onClick={handleNextDay} className="bg-gray-100 font-semibold rounded-full w-10 h-10 flex justify-center text-gray-900 text-sm hover:bg-lime-600 hover:text-white focus:ring-2 focus:ring-lime-500 transition-all">❯</button>
          </div>
          <div className="flex space-x-2">
            <div>
              <label
                  htmlFor="select-group"
                  className="block text-sm font-medium leading-6 text-gray-900">
                Select Group
              </label>
              <div className="mt-2">
                <select
                    id="select-group"
                    value={selectedUserGroup.id}
                    onChange={handleGroupDropdownChange}
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="">All groups</option>
                  {userGroups.map((userGroup) => (
                      <option key={userGroup.id} value={userGroup.id}>
                        {userGroup.name}
                      </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="select-slot" className="block text-sm font-medium leading-6 text-gray-900">
                Select Slot
              </label>
              <div className="mt-2">
                <select
                    id="select-slot"
                    value={selectedSlot}
                    onChange={handleSlotDropdownChange}
                    className="block rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
                >
                  <option value="LUNCH">LUNCH</option>
                  <option value="BREAKFAST">BREAKFAST</option>
                  <option value="SNACKS">SNACKS</option>
                  <option value="DINNER">DINNER</option>
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="search-item" className="block text-sm font-medium leading-6 text-gray-900">
                Search Item
              </label>
              <div className="mt-2">
                <input
                    type="text"
                    id="search-item"
                    placeholder="Search by item name"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="block md:min-w-80 rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                />
              </div>
            </div>
          </div>
        </div>
        {loading ? <Spinner /> : (
            <div className="overflow-x-auto px-0.5">
              <Table className="drop-shadow-none">
                <Table.Head>
                  <Table.HeadCell>Item</Table.HeadCell>
                  <Table.HeadCell>Quantity</Table.HeadCell>
                  <Table.HeadCell>Status</Table.HeadCell>
                  <Table.HeadCell>Expected Delivery Time</Table.HeadCell>
                  <Table.HeadCell>Action</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {renderTableBody()}
                </Table.Body>
              </Table>
            </div>
        )}
      </div>
  );
};

export default KitchenOrderLists;